/* 外部方法 */
import { useRouter } from 'vue-router';

/* 內部方法 */
import store from '@/store';

export default function useLogout() {
  const { socket } = store.useSocketStore();
  const router = useRouter();

  const logout = () => {
    router.push({ name: 'Login' });
    socket.stop();
  };

  return {
    logout
  };
}

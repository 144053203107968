/* 外部方法 */
import { ref, computed } from 'vue';
import { defineStore, storeToRefs } from 'pinia';

/* 內部方法 */
import { isDevelopment, nestFlatten } from '@sms/common/utils/helper';
import useRootStore from './root';
import useFlagmapStore from '@sms/common/store/flagMap';

/* API */
import locationService from '@/api/ajax/locationService';

/* 型別 */
import type LocationSet from '@sms/common/models/LocationSet';

export default defineStore('location', () => {
  /* Stores */
  const rootStore = useRootStore();
  const flagmapStore = useFlagmapStore();

  const { userRoleType } = storeToRefs(rootStore);
  const { flagMapGroupList } = storeToRefs(flagmapStore);

  /**
   * Flagmap 相關
   * store 內若有 computed 會依據 Flagmap 算值並且於登入前就載入則禁止使用解構取值，否則會有響應性問題
   */
  const RoleType = computed(() => flagMapGroupList.value.RoleType);
  const LocationType = computed(() => flagMapGroupList.value.LocationType);

  /* 地區相關 */
  const locations = ref<LocationSet[]>([]);
  const locationFlat = computed(() => nestFlatten(locations.value));

  const filterLocationByType = (acceptTypes: string[]) =>
    locationFlat.value.filter((location) => acceptTypes.includes(location.LocationTypeCode));

  // 根據當前登入角色回傳廳別列表
  const studioListByRoleType = computed(() => {
    if (!RoleType?.value) return [];

    switch (userRoleType.value) {
      case RoleType.value.PB:
        return filterLocationByType([LocationType.value.STUDIO, LocationType.value.EXCLUSIVE_STUDIO]);
      case RoleType.value.SHUFFLE_LEADER:
        return filterLocationByType([LocationType.value.SHUFFLEROOM]);
      default:
        return [];
    }
  });

  const fetchLocationList = async () => {
    try {
      const resp = await locationService.getMemberLocationTree({ OrderBy: 'Name' });
      locations.value = resp.data.Data;
    } catch (error) {
      if (isDevelopment) console.error(error);
    }
  };

  const getLocationName = (id: string) => locationFlat.value.find((location) => location.Id === id)?.Name;

  return {
    locations: locations,
    locationFlat,
    studioListByRoleType,
    fetchLocationList,
    filterLocationByType,
    getLocationName
  };
});

<template>
  <div :class="rootClass" v-bind="inheritAttrs.attrsWithoutClass">
    <div
      class="w-6 h-6 inline-flex items-center select-none relative flex-shrink-0"
      @click.stop="!disabled && onClick()"
    >
      <span :class="checkboxClassMerged">
        <SIcon v-show="indeterminate" :icon="mdiMinusBox" class="text-current w-6 h-6" />

        <SIcon
          v-show="!checked && !indeterminate"
          :icon="mdiCheckboxBlankOutline"
          class="text-current w-6 h-6"
          :class="errorMessage && '!text-red-500'"
        />

        <SIcon v-show="checked && !indeterminate" :icon="mdiCheckboxMarked" class="text-current w-6 h-6" />
      </span>
    </div>

    <slot name="label" v-bind="{ errorMessage, onClick }">
      <label
        v-if="label"
        class="text-sm cursor-pointer ml-1"
        :class="errorMessage && 'text-red-500'"
        @click="!disabled && onClick()"
      >
        {{ label }}
      </label>
    </slot>
  </div>
</template>

<script lang="ts">
export default {
  inheritAttrs: false
};
</script>

<script lang="ts" setup>
/* 外部方法 */
import { computed, watch, useAttrs } from 'vue';
import { cloneDeep, isEqual } from 'lodash-es';
import { useField } from 'vee-validate';
import { twMerge } from 'tailwind-merge';

/* 外部組件 */
import { mdiCheckboxBlankOutline, mdiCheckboxMarked, mdiMinusBox } from '@mdi/js';

/* 內部組件 */
import SIcon from '../SIcon/SIcon.vue';

/* 型別 */
interface Props {
  // 資料相關
  modelValue?: boolean | any[];
  value?: any;

  // 畫面相關
  label?: string;
  disabled?: boolean;
  indeterminate?: boolean;
  activeClass?: string;

  // 驗證相關
  validateKey?: string;
  validateOnMount?: boolean;
}

interface Emits {
  (e: 'update:model-value', value: boolean | any[]): void;
  (e: 'click', value: boolean | any[]): void;
}

const props = withDefaults(defineProps<Props>(), {
  // 資料相關
  modelValue: false,

  // 畫面相關
  indeterminate: false,
  disabled: false,
  activeClass: 'text-indigo-500',

  // 驗證相關
  validateKey: '',
  validateOnMount: false
});

const emit = defineEmits<Emits>();

const { value: validateValue, errorMessage } = useField<boolean | any[]>(props.validateKey, undefined, {
  initialValue: props.modelValue,
  validateOnMount: props.validateOnMount
});

watch(
  () => props.modelValue,
  (val) => {
    if (isEqual(val, validateValue.value)) return;
    validateValue.value = val;
  }
);

watch(validateValue, (val) => {
  if (isEqual(val, props.modelValue)) return;
  emit('update:model-value', val);
});

const onClick = () => {
  // 若綁定值為陣列，檢查陣列內有沒有相同的值
  if (Array.isArray(props.modelValue)) {
    const clone = cloneDeep(props.modelValue);
    const indexs: number[] = [];

    clone.forEach((value, index) => {
      if (isEqual(value, props.value)) indexs.push(index);
    });

    if (indexs.length) {
      indexs.forEach((index) => {
        clone.splice(index, 1);
      });
    } else {
      clone.push(props.value);
    }

    emit('update:model-value', clone);
    emit('click', clone);
  } else {
    emit('update:model-value', !props.modelValue);
    emit('click', !props.modelValue);
  }
};

const checked = computed(() => {
  if (Array.isArray(props.modelValue)) {
    const idx = props.modelValue.findIndex((value) => isEqual(value, props.value));
    return idx !== -1;
  }

  return props.modelValue;
});

/* UI 相關 */

// class 從繼承屬性中排除
const inheritAttrs = computed(() => {
  const { class: attrsClass, ...attrsWithoutClass } = useAttrs();

  return {
    attrsClass,
    attrsWithoutClass
  };
});

// 外層樣式
const rootClass = computed(() => twMerge('flex items-center', inheritAttrs.value.attrsClass as string));

const checkboxClassMerged = computed(() => {
  const defaultClass = 'absolute top-0 left-0 w-full h-full flex items-center justify-center';
  const disabledClass = props.disabled ? 'cursor-not-allowed text-gray-400' : 'cursor-pointer';
  const activeClass = checked.value || props.indeterminate ? props.activeClass : '';
  return twMerge(defaultClass, activeClass, disabledClass);
});
</script>

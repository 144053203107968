/// <reference lib="dom.iterable"/>

/* eslint-disable no-await-in-loop, no-promise-executor-return */

/* 外部方法 */
import axios from 'axios';
import { computed, onBeforeUnmount, ref, watch } from 'vue';
import { useMediaControls, useTimeoutFn, promiseTimeout } from '@vueuse/core';
import { v4 } from 'uuid';

/* 型別 */
import type { Ref } from 'vue';

type MediaStatus = 'connecting' | 'live' | 'disconnected';

const defaults = {
  unmute: false
};

/** 將 webrtc:// 轉為一般 http:// 但保留 querystring */
const urlParse = (url: string) => {
  const apiUrl = new URL(url);
  apiUrl.protocol = window.location.protocol;
  apiUrl.pathname = '/rtc/v1/play/';
  apiUrl.search = '';

  // 本機可以不用走 https
  if (apiUrl.hostname === 'localhost') {
    apiUrl.protocol = 'http:';
  }

  if (apiUrl.protocol === 'http:') {
    apiUrl.port = '1985';
  }

  return apiUrl.href;
};

/**
 * 確認媒體伺服器狀態
 * @param href 目前網址
 * @param host 媒體伺服器主機位置
 */
export async function checkServerStatus(href: string, host: string) {
  const url = new URL(href);

  // 如果是本機，就不用走 https
  if (host === 'localhost') {
    url.protocol = 'http:';
  }

  url.port = url.protocol === 'http:' ? '1985' : '';
  url.hostname = host || url.hostname;
  url.pathname = '/rtc/v1';
  url.hash = '';

  try {
    const { data } = await axios.get(url.href);
    return !(data.code as number);
  } catch (error) {
    return false;
  }
}

export default function useSRSRTC(video: Ref<HTMLVideoElement | undefined>, options = {} as Partial<typeof defaults>) {
  const { unmute } = { ...defaults, ...options };

  let destroyed = false;
  const stream = ref<MediaStream>();
  const status = ref<MediaStatus>('disconnected');
  const { playing, muted, volume } = useMediaControls(video);
  const isLoading = computed(() => status.value !== 'live' || !playing.value);

  let currentPeerConn: RTCPeerConnection | null;
  let currentUrl: string | null;
  let play: (url: string) => Promise<void>;

  /* webrtc 方法 */

  /* 維護訊號連線 */
  let previousBytes = 0;
  let freezeCount = 0;
  /** 連線守護 */
  const peerWatcher = useTimeoutFn(
    async () => {
      if (destroyed || !currentUrl) {
        peerWatcher.stop();
        return;
      }

      const bytes: number | undefined = [...((await currentPeerConn?.getStats())?.values() || [])].find(
        (x) => x.type === 'transport'
      )?.bytesReceived;

      // 如果沒取到數字代表連線沒有建立成功，三秒後重連
      if (typeof bytes !== 'number') {
        if (localStorage.getItem('debug:srs')) {
          console.log('[SRSRTC] get no packets, restart in 3 seconds...');
        }
        await promiseTimeout(3000);
        play(currentUrl);
        return;
      }

      // 如果 bytes 數沒有增加，或者只有傳送握手封包，就代表訊號卡住了
      if (bytes === previousBytes || (previousBytes && Math.abs(bytes - previousBytes) < 1200)) {
        status.value = 'connecting';
        freezeCount += 1;

        if (localStorage.getItem('debug:srs')) {
          console.log(`[SRSRTC] got ${bytes} bytes`);
        }

        // 凍結5次後重連
        if (freezeCount > 5) {
          if (localStorage.getItem('debug:srs')) {
            console.log('[SRSRTC] freeze 5 times, restart...');
          }
          play(currentUrl);
          return;
        }
      } else if (previousBytes > 0) {
        status.value = 'live';
        freezeCount = 0;
      }

      previousBytes = bytes;

      peerWatcher.start();
    },
    300,
    { immediate: false }
  );

  /** 關閉 WebRTC */
  const close = () => {
    if (!currentPeerConn) return;
    if (localStorage.getItem('debug:srs')) {
      console.log('[SRSRTC] closed');
    }

    peerWatcher.stop();
    currentPeerConn?.close();
    currentPeerConn = null;
    status.value = 'disconnected';
  };

  /**
   * 播放 WebRTC
   * @param url 網址，如：webrtc://localhost/live/livestream
   */
  play = async (url: string) => {
    previousBytes = 0;
    freezeCount = 0;

    try {
      if (destroyed) return;

      // 移除舊連線
      if (currentPeerConn) {
        close();
      }

      if (localStorage.getItem('debug:srs')) {
        console.log('[SRSRTC] connecting...');
      }
      status.value = 'connecting';

      const media = new MediaStream();
      const peerConn = new RTCPeerConnection();
      peerConn.ontrack = (e) => {
        media.addTrack(e.track);
      };

      peerConn.oniceconnectionstatechange = () => {
        if (peerConn?.iceConnectionState === 'disconnected' || peerConn?.iceConnectionState === 'closed') {
          status.value = 'disconnected';
          if (localStorage.getItem('debug:srs')) {
            console.log(`[SRSRTC] webrtc disconnected, restart...`);
          }

          play(url);
        }
      };

      peerConn.addTransceiver('audio', { direction: 'recvonly' });
      peerConn.addTransceiver('video', { direction: 'recvonly' });
      currentPeerConn = peerConn;
      currentUrl = url;

      const offer = await peerConn.createOffer();
      await peerConn.setLocalDescription(offer);

      const data = {
        api: urlParse(url),
        tid: v4().slice(0, 7),
        streamurl: url,
        clientip: null,
        sdp: offer.sdp
      };

      if (localStorage.getItem('debug:srs')) {
        console.log('[SRSRTC] Offer:', data);
      }

      const session = await axios.post(data.api, data);
      if (localStorage.getItem('debug:srs')) {
        console.log('[SRSRTC] Answer:', session.data);
      }

      if (session.data.code) throw new Error('RTC Server error');

      await peerConn.setRemoteDescription(new RTCSessionDescription({ type: 'answer', sdp: session.data.sdp }));

      stream.value = media;

      peerWatcher.start();
    } catch (error) {
      console.error(error);
      await promiseTimeout(3000);
      play(url);
    }
  };

  watch(
    [video, stream],
    () => {
      if (!video.value) return;
      if (!stream.value) return;

      /* eslint-disable no-param-reassign */
      video.value.srcObject = stream.value;

      // muted 參數在不同環境有不同問題，所以乾脆全部關起來
      if (!unmute) {
        muted.value = !unmute;
        volume.value = 0;
      }
    },
    { immediate: true }
  );

  onBeforeUnmount(() => {
    close();
    destroyed = true;
  });

  return {
    close,
    play,
    isLoading,
    stream,
    status: status as Readonly<Ref<MediaStatus>>
  };
}

<template>
  <div class="capacitorQRScanner" @click="startCamera">
    <teleport to="body">
      <SIcon
        @click="closeCamera"
        :icon="mdiCloseCircleOutline"
        :class="cameraActive ? 'visible' : 'invisible'"
        class="absolute right-4 top-10 w-20 h-20 text-zinc-500"
      />
    </teleport>

    <slot />
  </div>
</template>

<script lang="ts" setup>
/**
  使用本組件有幾個要求

  1. 需要讓 android 安裝以下套件：
    - @capacitor-community/camera-preview
    安裝套件後，執行 npx cap sync 安裝套件至 android

  2. App.vue 需要設定以下 css 讓鏡頭顯示
    body.camera-in-use {
      visibility: hidden;
    }

  3. 提供來自 Store 的 ref , isCameraActive 在 App.vue 控制鏡頭的顯示
 */

/* 外部方法 */
import QrScanner from 'qr-scanner';
import { onBeforeUnmount, computed } from 'vue';
import { Capacitor } from '@capacitor/core';
import { CameraPreview } from '@capacitor-community/camera-preview';
import { useIntervalFn } from '@vueuse/core';

/* 內部方法 */
import { isDevelopment } from '../../utils/helper';
import useScannerParser from '../../composables/useScannerParser';

/* 外部組件 */
import { mdiCloseCircleOutline } from '@mdi/js';

/* 內部組件 */
import SIcon from '@sms/components/SIcon/SIcon.vue';

/* 型別 */
import type { ScanResult } from '../../composables/useScannerParser';

interface Emits {
  (e: 'parsed', value: ScanResult): void;
  (e: 'update:model-value', value: boolean): void;
}

interface Props {
  /** 來自 store 的 ref value */
  modelValue: boolean;
}

const emit = defineEmits<Emits>();

const props = defineProps<Props>();

const cameraActive = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:model-value', value)
});

const scanner = useScannerParser();

const isWebPlatform = Capacitor.getPlatform() === 'web';

const parseImgInterval = useIntervalFn(
  async () => {
    try {
      const { value: base64PictureData } = await CameraPreview.captureSample({ quality: 50 });
      const dataUri = 'data:image/jpeg;base64,' + base64PictureData;

      const { data } = await QrScanner.scanImage(dataUri, { returnDetailedScanResult: true });
      if (!data) return;

      emit('parsed', scanner.parse(data));

      parseImgInterval.pause();
      await CameraPreview.stop();
      cameraActive.value = false;
    } catch (error) {
      if (isDevelopment) console.error(error);
    }
  },
  1000,
  {
    immediate: false,
    immediateCallback: true
  }
);

const closeCamera = async () => {
  try {
    if (!cameraActive.value) return;

    parseImgInterval.pause();
    await CameraPreview.stop();
    cameraActive.value = false;
  } catch (error) {
    if (isDevelopment) console.error(error);
  }
};

const startCamera = async () => {
  if (isWebPlatform || cameraActive.value) return;

  cameraActive.value = true;

  await CameraPreview.start({
    enableZoom: true,
    toBack: true
  });

  parseImgInterval.resume();
};

onBeforeUnmount(() => {
  if (cameraActive.value) closeCamera();
});
</script>

/* 外部方法 */
import { createRouter, createWebHashHistory } from 'vue-router';
import store, { pinia } from '@/store';

/** 內部組件 */
import Login from '@/views/Login.vue';
import NotFound from '@/views/404.vue';

/* 型別 */
import type { RouteRecordRaw } from 'vue-router';

/** Pinia 注入 */
store.useLocationStore(pinia);
const rootStore = store.useRootStore(pinia);

const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    props: true
  },
  {
    path: '/',
    // 看權限預設路徑 (洗牌員PB || 一般PB)
    redirect: '/studio',
    component: () => import('@/layouts/DefaultLayout.vue'),
    props: true,
    children: [
      // 攝影棚
      {
        path: 'studio',
        name: 'Studio',
        redirect: '/studio/all-desks',
        component: () => import('@/layouts/StudioLayout.vue'),
        meta: { requirePermission: true },
        children: [
          {
            path: 'all-desks',
            name: 'AllDesks',
            component: () => import('@/views/Studio/AllDesks/AllDesksIndex.vue'),
            props: true
          },

          {
            path: 'maintenance',
            name: 'Maintenance',
            component: () => import('@/views/Studio/Maintenance/MaintenanceIndex.vue'),
            props: true
          }
        ]
      },
      // 洗牌房
      {
        path: '/shuffle',
        name: 'Shuffle',
        redirect: '/shuffle/all-desks',
        component: () => import('@/layouts/ShuffleLayout.vue'),
        meta: { requirePermission: true },
        children: [
          {
            path: 'all-desks',
            name: 'ShuffleAllDesks',
            component: () => import('@/views/Shuffle/AllDesks/AllDesksIndex.vue'),
            props: true
          },
          {
            path: 'shoe-area',
            name: 'ShoeArea',
            component: () => import('@/views/Shuffle/ShoeArea/ShoeAreaIndex.vue'),
            props: true
          },
          {
            path: 'maintenance',
            name: 'shuffleMaintenance',
            component: () => import('@/views/Shuffle/Maintenance/MaintenanceIndex.vue'),
            props: true
          }
        ]
      },
      // 新版倉儲
      {
        path: '/warehouse',
        name: 'Warehouse',
        redirect: '/warehouse/inventory',
        component: () => import('@/layouts/WarehouseLayout.vue'),
        meta: { requirePermission: true },
        children: [
          // 庫存查詢
          {
            path: 'inventory',
            name: 'Warehouse-Inventory',
            component: () => import('@/views/Warehouse/Inventory.vue'),
            props: true
          },
          // 執行單據
          {
            path: 'order',
            redirect: '/warehouse/order/overview',
            component: () => import('@/views/Warehouse/Order/Index.vue'),
            props: true,
            children: [
              // 單據總覽
              {
                path: 'overview',
                name: 'Warehouse-OrderOverview',
                component: () => import('@/views/Warehouse/Order/Overview.vue'),
                props: true
              },
              // 特定單據
              {
                path: ':orderId',
                name: 'Warehouse-Order',
                component: () => import('@/views/Warehouse/Order/Order.vue'),
                props: true
              }
            ]
          },
          // 建立單據與編輯畫面
          {
            path: 'order',
            name: 'Order',
            component: () => import('@/views/Warehouse/Order.vue'),
            props: true,
            children: [
              {
                path: 'receive/:orderId?',
                name: 'Order-Receive',
                component: () => import('@/views/Warehouse/Create/Receive.vue'),
                props: true
              },
              {
                path: 'inspection/:orderId?',
                name: 'Order-Inspection',
                component: () => import('@/views/Warehouse/Create/Inspection.vue'),
                props: true
              },
              {
                path: 'withdraw/:orderId?',
                name: 'Order-Withdraw',
                component: () => import('@/views/Warehouse/Create/Withdraw.vue'),
                props: true
              },
              {
                path: 'changelocation/:orderId?',
                name: 'Order-ChangeLocation',
                component: () => import('@/views/Warehouse/Create/ChangeLocation.vue'),
                props: true
              },
              {
                path: 'discard/:orderId?',
                name: 'Order-Discard',
                component: () => import('@/views/Warehouse/Create/Discard.vue'),
                props: true
              },
              {
                path: 'repair/:orderId?',
                name: 'Order-Repair',
                component: () => import('@/views/Warehouse/Create/Repair.vue'),
                props: true
              },
              {
                path: 'putaway/:orderId?',
                name: 'Order-Putaway',
                component: () => import('@/views/Warehouse/Create/PutawayAndReturned.vue'),
                props: true
              },
              {
                path: 'returned/:orderId?',
                name: 'Order-Returned',
                component: () => import('@/views/Warehouse/Create/PutawayAndReturned.vue'),
                props: true
              }
            ]
          }
        ]
      },
      // 舊版倉儲
      {
        path: '/equipment',
        name: 'Equipment',
        redirect: '/equipment/inventory',
        component: () => import('@/layouts/EquipmentLayout.vue'),
        meta: { requirePermission: true },
        children: [
          { path: 'cart', name: 'Cart', component: () => import('@/views/Equipment/Cart/CartIndex.vue'), props: true },
          {
            path: 'deposit',
            name: 'Deposit',
            component: () => import('@/views/Equipment/Deposit/DepositIndex.vue'),
            props: true
          },
          {
            path: 'inventory',
            name: 'Inventory',
            component: () => import('@/views/Equipment/Inventory/InventoryIndex.vue'),
            props: true
          },
          {
            path: 'withdraw',
            name: 'Withdraw',
            component: () => import('@/views/Equipment/Withdraw/WithdrawIndex.vue'),
            props: true
          }
        ]
      },
      // 事件
      {
        path: '/event',
        name: 'Event',
        redirect: '/event/report',
        component: () => import('@/layouts/EventLayout.vue'),
        meta: { requirePermission: true },
        children: [
          {
            path: 'report',
            name: 'Report',
            component: () => import('@/views/Event/Report/ReportIndex.vue'),
            props: true
          },
          {
            path: 'report/create',
            name: 'ReportCreate',
            component: () => import('@/views/Event/Report/ReportEdit.vue'),
            props: true
          },
          {
            path: 'report/edit/:input',
            name: 'ReportEdit',
            component: () => import('@/views/Event/Report/ReportEdit.vue'),
            props: true
          }
        ]
      },
      {
        path: 'shuffleDesk/:shuffleDeskId',
        name: 'ShuffleDesk',
        component: () => import('@/views/Shuffle/shuffleDesk/DeskIndex.vue'),
        props: true
      },
      {
        path: 'desk/:deskId',
        name: 'Desk',
        component: () => import('@/views/Studio/Desk/DeskIndex.vue'),
        props: true,
        children: [
          {
            path: 'bac',
            name: 'BAC',
            component: () => import('@/views/Studio/Desk/BAC/BACIndex.vue'),
            props: true
          },
          {
            path: 'drt',
            name: 'DRT',
            component: () => import('@/views/Studio/Desk/DRT/DRTIndex.vue'),
            props: true
          },
          {
            path: 'bjb',
            name: 'BJB',
            component: () => import('@/views/Studio/Desk/BJB/BJBIndex.vue'),
            props: true
          },
          {
            path: 'sib',
            name: 'SIB',
            component: () => import('@/views/Studio/Desk/SIB/SIBIndex.vue'),
            props: true
          },
          {
            path: 'rou',
            name: 'ROU',
            component: () => import('@/views/Studio/Desk/ROU/ROUIndex.vue'),
            props: true
          },
          {
            path: 'fan',
            name: 'FAN',
            component: () => import('@/views/Studio/Desk/FAN/FANIndex.vue'),
            props: true
          },
          {
            path: 'sed',
            name: 'SED',
            component: () => import('@/views/Studio/Desk/SED/SEDIndex.vue'),
            props: true
          },
          {
            path: 'mbl',
            name: 'MBL',
            component: () => import('@/views/Studio/Desk/MBL/MBLIndex.vue'),
            props: true
          }
        ]
      },
      // 個人檔案頁面
      {
        path: '/user',
        name: 'User',
        component: () => import('@/views/User/User.vue')
      },
      {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: NotFound
      }
    ]
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

router.beforeEach((to) => {
  if (to.name !== 'Login' && !rootStore.isLoggedIn) {
    return { name: 'Login' };
  }

  if (to.matched.some((record) => record.meta.requirePermission)) {
    const hasPermission = to.matched.some((record) => rootStore.siderbar.some((x) => x.Uri === record.path));

    if (!rootStore.isLoggedIn || !hasPermission) {
      return { name: 'Login' };
    }
  }

  return true;
});

export default router;

/* 外部方法 */
import { readonly } from 'vue';
import { defineStore } from 'pinia';

/* API */
import memberService from '@/api/ajax/memberService';

/* 型別 */
import ShuffleDeskSet from '@sms/common/models/ShuffleDeskSet';
import MemberSet from '@sms/common/models/MemberSet';

export default defineStore('shuffleDesk', {
  state: () => ({
    shuffleDeskData: new ShuffleDeskSet(),
    shufflerInfo: new MemberSet()
  }),

  getters: {
    readonlyShuffleDeskData: (state) => readonly(state.shuffleDeskData)
  },

  actions: {
    setShuffleDesk(serverDeskData: ShuffleDeskSet) {
      // 避免伺服器意外地傳送空物件
      if (!serverDeskData) return;

      /** 確認是否為同一桌的資料 */
      const isSameDesk = this.shuffleDeskData.Id === serverDeskData.Id;

      // 如果是同一桌的資料
      if (isSameDesk) {
        const isLatestData = +new Date(serverDeskData.UpdateDatetime) >= +new Date(this.shuffleDeskData.UpdateDatetime);

        // 比對是否為最新資料
        if (!isLatestData) return;

        this.shuffleDeskData = serverDeskData;
      } else {
        this.shuffleDeskData = serverDeskData;
      }
    },

    clearShufflerInfo() {
      this.shufflerInfo = new MemberSet();
    },

    async requestShufflerInfo(deskId: string) {
      try {
        const resp = await memberService.getShuffler(deskId);
        this.shufflerInfo = resp.data.Data;
      } catch (error) {
        // 清空 shuffler 資訊
        this.shufflerInfo = new MemberSet();
        throw error;
      }
    }
  }
});

<template>
  <div class="flex flex-col items-center justify-center w-full h-full py-8">
    <div
      class="text-4xl font-normal mb-8 bg-gradient-to-b from-indigo-400 to-sky-200 bg-clip-text w-full text-center"
      style="-webkit-text-fill-color: transparent"
    >
      {{ t('Tablet.Login_Interface') }}
    </div>

    <div class="max-w-sm md:max-w-md w-full bg-white px-12 py-8 rounded-2xl">
      <div v-if="hasCamera" class="flex justify-center items-center mb-10">
        <div class="w-[200px] bg-black">
          <CapacitorQRScanner v-model="isCameraActive" @parsed="scanHandler">
            <video ref="videoElement" autoplay />
          </CapacitorQRScanner>
        </div>
      </div>

      <form @submit.prevent="submit">
        <div
          :class="[
            'flex bg-[#EDEEEF] rounded-[5px] border',
            formErrors.selectedRole ? 'border-red-500' : 'border-[#e2e2e2]'
          ]"
        >
          <div class="flex-1 rounded-r-md">
            <SSelect
              :items="roleOption"
              hide-details="auto"
              v-model="form.selectedRole"
              :placeholder="t('Tablet.Login_User_Role')"
            />
          </div>
        </div>
        <div class="leading-4 text-xs text-red-500 text-right mt-1 mb-2 min-h-[16px]">
          <span>{{ formErrors.selectedRole }}</span>
        </div>

        <div>
          <div
            :class="[
              'flex bg-[#EDEEEF] rounded-[5px] border',
              formErrors.account ? 'border-red-500' : 'border-[#e2e2e2]'
            ]"
          >
            <div class="w-[54px] flex items-center justify-center">
              <SIcon :icon="mdiAccount" class="w-6 h-6 text-zinc-500" />
            </div>

            <div class="w-px bg-[#e2e2e2] my-[10px]"></div>

            <div class="flex-1 rounded-r-md">
              <input
                v-model="form.account"
                type="text"
                :class="[
                  'w-full rounded-r-[5px] text-xs h-12 bg-[#EDEEEF] disabled:text-gray-600 border-none focus:ring-indigo-500 focus:ring-2',
                  formErrors.account ? 'text-red-500' : 'text-gray-600'
                ]"
                :placeholder="t('Tablet.Login_UserID')"
                :disabled="!(isLocal || isQA) || loading.visible.value"
                @blur="validate"
                @touchcancel="validate"
                @touchend="validate"
              />
            </div>
          </div>

          <div class="leading-4 text-xs text-red-500 text-right mt-1 mb-2 min-h-[16px]">
            <span>{{ formErrors.account }}</span>
          </div>
        </div>

        <div>
          <div
            :class="[
              'relative flex items-center bg-[#EDEEEF] rounded-[5px] border',
              formErrors.password ? 'border-red-500' : 'border-[#e2e2e2]'
            ]"
          >
            <div class="w-[54px] flex items-center justify-center">
              <SIcon :icon="mdiLock" class="w-6 h-6 text-zinc-500" />
            </div>

            <div class="w-px bg-[#e2e2e2] my-[10px]"></div>

            <div class="flex-1 rounded-r-md">
              <input
                v-model="form.password"
                :type="showPassword ? 'text' : 'password'"
                :class="[
                  'w-full rounded-r-[5px] text-xs h-12 bg-[#EDEEEF] border-none pr-12 focus:ring-indigo-500 focus:ring-2',
                  formErrors.password ? 'text-red-500' : 'text-gray-600'
                ]"
                :placeholder="t('Common.Password')"
                :disabled="loading.visible.value"
                @blur="validate"
              />
            </div>

            <SIcon
              :icon="mdiEye"
              :class="[
                'absolute right-4 w-6 h-6  cursor-pointer hover:text-blue-500',
                showPassword ? 'text-blue-500' : 'text-[#C4C6C9]'
              ]"
              @click="showPassword = !showPassword"
            />
          </div>

          <div class="leading-4 text-xs text-red-500 text-right mt-1 mb-2 min-h-[16px]">
            <span>{{ formErrors.password }}</span>
          </div>
        </div>

        <SBtn type="submit" class="w-full !h-[50px] bg-indigo-500">
          {{ t('Common.Login') }}
        </SBtn>

        <div v-if="formErrors.system" class="leading-4 text-xs text-red-500 text-center mt-6">
          <span>{{ formErrors.system }}</span>
        </div>
      </form>
    </div>

    <div v-if="isWebPlatform" class="flex items-center mt-12">
      <!-- 相機權限 -->
      <div
        class="flex items-center justify-center text-gray-300 h-[45px] px-4 border border-gray-300 rounded-[5px] cursor-pointer hover:opacity-80"
        @click="initVideo"
      >
        <SIcon :icon="mdiCamera" class="w-6 h-6 mr-2" />
        <span class="whitespace-nowrap">{{ t('Admin.CameraPermission') }}</span>
      </div>
    </div>

    <div v-if="permissionNotAllowed" class="leading-4 text-sm text-red-500 text-center mt-6">
      <span>{{ t('Common.CameraDenied') }}</span>
    </div>

    <CapacitorAppUpdater site="site-tablet" />
  </div>
</template>

<script lang="ts" setup>
/* 外部方法 */
import { ref, reactive, computed, onMounted, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useTimeoutFn } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import { Capacitor } from '@capacitor/core';

/* 內部方法 */
import store, { pinia } from '@/store';
import connectionLogger from '@sms/common/utils/connectionLogger';
import { isDevelopment, isLocal, isQA } from '@sms/common/utils/helper';
import { yupObject, string } from '@sms/common/plugins/yup';
import useQRScanner from '@sms/common/composables/useQRScanner';
import useModal from '@/composables/useModal';
import useI18n from '@sms/common/composables/useI18n';
import useLoading from '@sms/common/composables/useLoading';
import useLogout from '@/composables/useLogout';

/* 外部組件 */
import { mdiAccount, mdiLock, mdiEye, mdiCamera } from '@mdi/js';

/* 內部組件 */
import SSelect from '@sms/components/SSelect/SSelect.vue';
import SIcon from '@sms/components/SIcon/SIcon.vue';
import SBtn from '@sms/components/SBtn/SBtn.vue';
import CapacitorQRScanner from '@sms/common/components/Capacitor/CapacitorQRScanner.vue';
import CapacitorAppUpdater from '@sms/common/components/Capacitor/CapacitorAppUpdater.vue';

/* API */
import logRecorderService from '@sms/common/api/logRecorderService';
import authService from '@/api/ajax/authService';

/* 型別 */
import CustomError, { editCustomErrorInfo } from '@sms/common/models/CustomError';
import { LogLevel } from '@microsoft/signalr';
import { ValidationError } from 'yup';
import type { ScanResult } from '@sms/common/composables/useScannerParser';

/* 系統元件 */
const router = useRouter();
const { modalOk } = useModal();
const { t, f, setLocale } = useI18n();
const loading = useLoading();
const { logout } = useLogout();

/* Stores */
const rootStore = store.useRootStore();
const modalStore = store.useModalStore();
const locationStore = store.useLocationStore();
const socketStore = store.useSocketStore();
const { flagMapGroupList } = store.useFlagMapStore(pinia);

const { socket } = socketStore;
const { isSocketConnected } = storeToRefs(socketStore);
const { studioListByRoleType } = storeToRefs(locationStore);
const { currentSiderbar } = storeToRefs(rootStore);

/* android 鏡頭控制  */
const { isCameraActive } = storeToRefs(rootStore);

/* Flagmap 相關 */
const { RoleType } = flagMapGroupList;

/* 共用變數 */
const hasCamera = ref(false); // 是否有鏡頭
const showPassword = ref(false); // 是否可顯示密碼
const isWebPlatform = Capacitor.getPlatform() === 'web';

/* 表單相關 */
const form = reactive({
  account: '',
  password: '',
  selectedRole: ''
});

const formErrors = reactive({
  account: '',
  password: '',
  selectedRole: '',
  system: ''
});

const roleOption = computed(() => [
  { text: f('RoleType', RoleType?.PB), value: RoleType?.PB },
  { text: f('RoleType', RoleType?.SHUFFLE_LEADER), value: RoleType?.SHUFFLE_LEADER }
]);

/* 根據角色切換 Hub  */
const roleHub = computed(() => (form.selectedRole === RoleType.PB ? 'PbHub' : 'ShuffleLeaderHub'));

const schema = computed(() =>
  yupObject({
    account: string().uuid(t('Error.Input_Valid_UUID')).required(t('Error.Input_Valid_Required')),
    password: string().required(t('Error.Input_Valid_Required')),
    selectedRole: string().required(t('Error.Input_Valid_Required'))
  })
);

/* QRCode 掃描相關 */
const scanHandler = ({ type, data: scanData }: ScanResult) => {
  if (type !== 'MEMBER') return;
  if (typeof scanData !== 'string') return;
  form.account = scanData;
};

const { videoElement, initVideo, permissionNotAllowed } = useQRScanner(scanHandler);

/** 重置 Store */
const clearData = () => {
  if (isSocketConnected.value) socket.stop();
  rootStore.resetStore();
};

/* 登入流程 */
const validate = () => {
  try {
    // 重置錯誤訊息
    formErrors.account = '';
    formErrors.password = '';
    formErrors.selectedRole = '';
    formErrors.system = '';

    schema.value.validateSync(
      { account: form.account, password: form.password, selectedRole: form.selectedRole },
      { abortEarly: false }
    );

    return true;
  } catch (error) {
    // 顯示 yup 驗證訊息 (多驗證規則下也只顯示一個)
    if (error instanceof ValidationError) {
      const keys = Object.keys(formErrors);

      error.inner.forEach((x) => {
        if (x.path && keys.includes(x.path)) {
          const key = x.path as keyof typeof formErrors;
          formErrors[key] = x.message;
        }
      });
    }

    return false;
  }
};

const initSocketGlobalEvent = () => {
  // 取得 LOG 資訊
  const getLogMessage = () => ({
    PB: rootStore.user.Id,
    Token: rootStore.token
  });
  // 後踢前
  socket.on('OnStopConnection', () => {
    logRecorderService.postLog({
      LoggerName: 'tablet',
      Msg: [
        {
          ...getLogMessage(),
          Time: new Date(),
          LogLevel: LogLevel.Trace,
          Message: `[系統後踢前 OnStopConnection 觸發]`
        }
      ]
    });
    socket.stop();
  });

  const saveLogOnReconnecting = (error?: Error | undefined) => {
    if (!socket.logger) throw new Error('Socket logger not found.');

    connectionLogger.onreconnectingLogger(
      'tablet',
      error?.message,
      getLogMessage(),
      rootStore.user.Id,
      rootStore.machineId
    );

    connectionLogger.pushSignalrLogs('tablet', socket.logger, rootStore.user.Id, rootStore.machineId);
  };

  // 嘗試重連時紀錄 LOG
  socket.onReconnecting(saveLogOnReconnecting);

  const saveLogOnReconnected = () => {
    connectionLogger.onreconnectedLogger('tablet', getLogMessage(), rootStore.user.Id, rootStore.machineId);
  };

  // 重連成功時紀錄 LOG
  socket.onReconnected(saveLogOnReconnected);

  // 目前使用的帳號被編輯後強制重新登入
  socket.on('OnInfoChange', async () => {
    await modalOk({ title: t('Error.AccountModified') });
    logout();
  });

  // 斷線時紀錄 LOG & 返回登入頁
  socket.onClose(async (error) => {
    router.push({ name: 'Login' });

    if (!socket.logger) throw new Error('Socket logger not found.');

    connectionLogger.oncloseLogger('tablet', error?.message, getLogMessage(), rootStore.machineId);
    await connectionLogger.pushSignalrLogs('tablet', socket.logger, rootStore.user.Id, rootStore.machineId);

    useTimeoutFn(() => {
      if (!socket.logger) throw new Error('Socket logger not found.');
      connectionLogger.pushSignalrLogs('tablet', socket.logger, rootStore.user.Id, rootStore.machineId);
    }, 2000);
  });
};

const fetchUserInfo = async () => {
  try {
    const [siderbarResp] = await Promise.all([authService.getSiderbar(), locationStore.fetchLocationList()]);

    // 注入該帳號底下的功能選單
    if (siderbarResp.data.Data.length) rootStore.siderbar = siderbarResp.data.Data[0].SubMenu;

    // 檢查目前的廳別列表是否有可用廳別
    // 若無可用廳別則可能是該帳號沒有勾選到相對應登入角色的廳別，例如攝影棚 PB 至少需有獨享廳或共享廳的地區權限，洗牌房 PB 則必須要有洗牌房的地區權限
    if (!studioListByRoleType.value.length) throw new CustomError('Error', 'NoAvailableStudio');

    // 將系統當前選擇的廳別設定為廳別列表的第一廳
    rootStore.currentStudioId = studioListByRoleType.value[0].Id || '';

    // 登入後跳轉的路由會是該帳號擁有權限的第一個功能，例如該帳號擁有的功能是 [攝影棚, 事件報告]，那麼預設會跳轉到攝影棚
    const firstRoute = currentSiderbar.value[0].Uri;

    // 如果該帳號完全沒有任何功能則拋出錯誤
    if (!firstRoute) throw new CustomError('Error', 'NoAvailableService');

    // 嘗試連線紀錄 Log
    connectionLogger.onconnectingLogger('tablet', '準備嘗試連線', rootStore.token, rootStore.machineId);

    // SignalR 連線
    await socket
      .build({ domain: import.meta.env.VITE_TABLET_SIGNALR_SERVER, route: roleHub.value }, rootStore.token, {
        loggerSite: 'tablet'
      })
      .start();

    // 連線成功紀錄 Log
    connectionLogger.onconnectedLogger('tablet', '連線成功', rootStore.token, rootStore.machineId);

    initSocketGlobalEvent();

    rootStore.isLoggedIn = true;

    router.push(firstRoute);
  } catch (error) {
    if (isDevelopment) console.error(error);

    if (error instanceof CustomError) {
      await modalOk({ title: error.message });
    }

    clearData();
  }
};

const submit = async () => {
  try {
    const isValid = validate();
    if (!isValid) return;

    loading.show();

    const response = await authService.login(form.account, form.password, form.selectedRole);
    const { Data } = response.data;

    rootStore.tokenInit(Data.token);
    rootStore.machineIdInit();

    rootStore.user = Data.member;
    rootStore.userRoleType = form.selectedRole; // PB 的權限是以登入時的選擇為主，故此處紀錄使用者登入時選擇的 RoleType
    rootStore.userLocale = Data.member.LangTypeCode;
    rootStore.userId = Data.member.Id as string;

    setLocale(Data.member.LangTypeCode);

    await fetchUserInfo();

    editCustomErrorInfo({ PB: rootStore.user.Nickname, MachineID: rootStore.machineId });
  } catch (error) {
    if (error instanceof CustomError) {
      const accountErrors = [-2110002];
      const passwordErrors = [-2110006];

      if (accountErrors.includes(Number(error.code))) {
        formErrors.account = error.message;
      } else if (passwordErrors.includes(Number(error.code))) {
        formErrors.password = error.message;
      } else {
        formErrors.system = error.message;
      }
    }

    clearData();
  } finally {
    loading.hide();
  }
};

/** 判斷裝置 */
const getDevices = async () => {
  const mediaDeviceList = await navigator.mediaDevices.enumerateDevices();
  hasCamera.value = !!mediaDeviceList.find((x) => x.kind === 'videoinput');
};

const initData = () => {
  getDevices();
  clearData();

  if (import.meta.env.DEV) {
    form.selectedRole = RoleType?.PB;
    form.account = '33c995fc-fdd7-4bce-941c-a805414a8cba';
    form.password = 'a84268426';
  }
};

/** 開發模式監聽角色選單變動，自動套用各角色帳號 */
watch(
  () => form.selectedRole,
  (role) => {
    if (import.meta.env.DEV) {
      form.account =
        role === RoleType?.PB ? '33c995fc-fdd7-4bce-941c-a805414a8cba' : 'cc7b5b43-b671-4d8d-827d-fd5a03ad7aea';
    }
  }
);

onMounted(() => {
  // 當使用者被導回登入頁時(例如同帳號在另一裝置登入)清除未關閉的提示燈箱
  modalStore.clear();
  initData();
});
</script>

/* 外部方法 */
import { onMounted } from 'vue';
import { Device } from '@capacitor/device';
import { Capacitor } from '@capacitor/core';
import { useIntervalFn } from '@vueuse/core';

interface UseBatteryCheckerParams {
  onDeviceChargingCallback: Function;
  checkIntervalInSec?: number;
  startTimerImmediately?: boolean;
}

export default function useBatteryChecker(options: UseBatteryCheckerParams) {
  const { onDeviceChargingCallback, checkIntervalInSec = 5, startTimerImmediately = true } = options;

  const batteryCheck = async () => {
    if (!Capacitor.isNativePlatform()) return;

    const { isCharging } = await Device.getBatteryInfo();

    if (isCharging) onDeviceChargingCallback();
  };

  const checkBatteryInterval = useIntervalFn(batteryCheck, checkIntervalInSec * 1000, {
    immediate: startTimerImmediately,
    immediateCallback: true
  });

  return {
    checkBatteryInterval
  };
}

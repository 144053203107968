<template>
  <div
    class="fixed flex left-0 top-0 right-0 bottom-0 h-full w-full overflow-hidden bg-gray-800 text-white min-h-[640px] flex-col"
  >
    <LoadingOverlay
      v-model:show="systemLoading.show"
      v-model:reload="systemLoading.show"
      :api-callback="fetchSystemAPI"
      site="tablet"
    />

    <template v-if="!systemLoading.show">
      <div class="grow min-h-0">
        <router-view />
      </div>

      <div class="h-7">
        <StatusBar />
      </div>
    </template>

    <SModal
      v-for="(modal, index) in modals"
      :key="index"
      :closable="false"
      :model-value="true"
      :options="modal"
      :content-class="modal.contentClass"
      @on-cancel="modal.onCancel && modal.onCancel()"
      @on-confirm="modal.onConfirm && modal.onConfirm()"
    />

    <SMessage />

    <SLoading
      v-model="loading.visible.value"
      :loading-text="loading.text.value"
      :loading-color="loading.color.value"
      teleport-disabled
    />
  </div>
</template>

<script setup lang="ts">
/* 外部方法 */
import { onMounted, onBeforeUnmount, reactive, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';

/* 內部方法 */
import store from '@/store';
import { pokerLoader } from '@sms/common/utils/poker';
import { roadmapLoader } from '@sms/common/utils/RoadHelper';
import { initAxiosCustomErrorInterceptor } from '@/api/ajax/settings';
import useLoading from '@sms/common/composables/useLoading';
import useModal from '@/composables/useModal';
import useFontFamily from '@sms/common/composables/useFontFamily';
import useI18n from '@sms/common/composables/useI18n';
import { getApiBaseUrl } from '@/api/ajax/settings';
import useLogout from '@/composables/useLogout';
import useBatteryChecker from '@sms/common/composables/Capacitor/useBatteryChecker';
import { isDevelopment } from '@sms/common/utils/helper';

/* API */
import flagMapService from '@/api/ajax/flagMapService';

/* 內部組件 */
import SModal from '@sms/components/SModal/SModal.vue';
import SMessage from '@sms/components/SMessage/SMessage.vue';
import SLoading from '@sms/components/SLoading/SLoading.vue';
import LoadingOverlay from '@sms/common/components/LoadingOverlay/LoadingOverlay.vue';
import StatusBar from '@/components/StatusBar.vue';

/* 系統元件 */
const route = useRoute();
const router = useRouter();
const { fetchTranslation } = useI18n();

/* Stores */
const root = store.useRootStore();
const flagMapStore = store.useFlagMapStore();
const modalStore = store.useModalStore();
const socketStore = store.useSocketStore();

/* android 鏡頭控制  */
const { isCameraActive } = storeToRefs(root);

watch(isCameraActive, (cameraActive) => {
  if (cameraActive) {
    document.body.classList.add('camera-in-use');
  } else {
    document.body.classList.remove('camera-in-use');
  }
});

/* android 充電自動登出 */
const { logout } = useLogout();
const handleChargingLogout = () => {
  if (!isDevelopment && route.name !== 'Login') logout();
};
useBatteryChecker({ onDeviceChargingCallback: handleChargingLogout });

/* Socket 相關 */
const { startUpdateSocketState, stopUpdateSocketState } = socketStore;

/* 全域 Modal 控制 */
const { modals } = storeToRefs(modalStore);

/* 全域 Loading 相關 */
const loading = useLoading();

/** 字型控制 */
useFontFamily();

/** 初始化 Ajax 請求的錯誤處理方式 */
initAxiosCustomErrorInterceptor(router, useModal);

/**
 * 系統 Loading 相關
 * 進入系統前必須等待 `LoadingOverlay.vue` 將系統所需的 API 讀取完成
 */
const systemLoading = reactive({
  show: false,
  reload: false
});

const fetchSystemAPI = async () => {
  await Promise.all([
    fetchTranslation(await getApiBaseUrl()),
    flagMapStore.fetchFlagMapData(flagMapService.getAll),
    pokerLoader.load(),
    roadmapLoader.load()
  ]);
};

/** 禁止 Double Tap 的縮小和放大 */
// let lastTouchEnd = 0;

// document.addEventListener(
//   'touchstart',
//   (event) => {
//     if (event.touches.length > 1) {
//       event.preventDefault();
//     }
//   },
//   { passive: false }
// );

// document.addEventListener(
//   'touchend',
//   (event) => {
//     const now = new Date().getTime();
//     if (now - lastTouchEnd <= 300) event.preventDefault();
//     lastTouchEnd = now;
//   },
//   false
// );

onMounted(startUpdateSocketState);
onBeforeUnmount(stopUpdateSocketState);
</script>

<style lang="scss">
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import 'animate.css';
@import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+SC:wght@300;400;500;700&family=Noto+Sans+TC:wght@300;400;500;700&family=Noto+Sans:wght@400;500;700&display=swap');

* {
  -webkit-tap-highlight-color: none;
}

body {
  overflow: hidden;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none;
  touch-action: pan-y pan-x;
}

label.required {
  @apply after:content-['*'] after:text-red-700 after:ml-1;
}

body.camera-in-use {
  visibility: hidden;
}
</style>

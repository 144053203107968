/* 外部方法 */
import axios from 'axios';
import { Device } from '@capacitor/device';
import { Capacitor } from '@capacitor/core';

/* 內部方法 */
import useRootStore from '@/store/modules/root';
import { isDevelopment } from '@sms/common/utils/helper';

/* 型別 */
import CustomError from '@sms/common/models/CustomError';
import type ResponseBaseModel from '@sms/common/interfaces/ResponseBaseModel';
import type useModalTypes from '@/composables/useModal';
import type { AxiosInstance, AxiosError, AxiosHeaders } from 'axios';
import type { Router } from 'vue-router';

const customAxios: AxiosInstance = axios.create({
  baseURL: '/api'
});

const isNativeAndroid = Capacitor.isNativePlatform() && Capacitor.getPlatform() === 'android';
const isLocalDev = import.meta.env.DEV;
const isLocalNativeAndroid = isNativeAndroid && isLocalDev;

export const getApiBaseUrl = async () => {
  if (!isLocalNativeAndroid) return '';

  const { isVirtual } = await Device.getInfo();
  return isVirtual ? 'https://10.0.2.2:8083' : '';
};

customAxios.interceptors.request.use(async (config) => {
  if (isLocalNativeAndroid) {
    const { isVirtual } = await Device.getInfo();

    if (isVirtual) config.baseURL = 'https://10.0.2.2:8083/api';
  }

  const rootStore = useRootStore();

  (config.headers as AxiosHeaders).set('Authorization', `Bearer ${rootStore.token}`);

  return config;
});

export const initAxiosCustomErrorInterceptor = (router: Router, useModal: typeof useModalTypes) => {
  const { modalOk } = useModal();

  const customErrorInterceptor = async (error: AxiosError<ResponseBaseModel<unknown>>) => {
    if (isDevelopment) console.error(error);
    if (!error.response) {
      // FIXME: 發生的情況釐清與錯誤定義
      throw new CustomError('Error', 'Axios_Error_No_Response', null, error);
    }

    const { status } = error.response;
    const { ErrCode } = error.response.data;

    switch (status) {
      case 400:
        throw new CustomError('Exception', ErrCode.toString(), null, error);
      case 401:
      case 403:
        await modalOk({ title: new CustomError('Error', 'AuthFailed', null, error).message });
        router.replace({ name: 'login' });
        break;
      case 404:
        await modalOk({ title: new CustomError('Error', '404', null, error).message });
        router.back();
        break;
      default: {
        let err: CustomError;
        if (ErrCode) {
          err = new CustomError('Exception', ErrCode.toString(), null, error);
        } else {
          err = new CustomError('Error', 'Remote_Communication', null, error);
        }
        throw err;
      }
    }
  };

  customAxios.interceptors.response.use((response) => response, customErrorInterceptor);
};

export default customAxios;

import { DateTime } from 'luxon';
import { mdiAlertCircle } from '@mdi/js';

export const alertTimeFormat = (time: Date): string =>
  DateTime.fromJSDate(time).setZone('UTC').toFormat("yyyy/MM/dd HH:mm:ss 'UTC'Z");

export const alertConfig = {
  blue: {
    class: 'bg-blue-100',
    icon: mdiAlertCircle,
    iconClass: 'text-blue-400',
    titleClass: 'text-blue-700',
    subTitleClass: 'text-blue-700'
  },

  red: {
    class: 'bg-red-50',
    icon: mdiAlertCircle,
    iconClass: 'text-red-400',
    titleClass: 'text-red-800',
    subTitleClass: 'text-red-800'
  },

  amber: {
    class: 'bg-amber-50',
    icon: mdiAlertCircle,
    iconClass: 'text-amber-400',
    titleClass: 'text-amber-800',
    subTitleClass: 'text-amber-800'
  },

  violet: {
    class: 'bg-violet-100',
    icon: mdiAlertCircle,
    iconClass: 'text-violet-400',
    titleClass: 'text-violet-700',
    subTitleClass: 'text-violet-700'
  },

  emergencyNews: {
    class: 'bg-violet-100',
    icon: mdiAlertCircle,
    iconClass: 'text-red-500',
    titleClass: 'text-red-500',
    subTitleClass: 'text-red-500'
  },

  none: {
    class: '',
    icon: '',
    iconClass: '',
    titleClass: '',
    subTitleClass: ''
  }
};

export const modalConfig = {
  blue: {
    contentClass: 'bg-blue-100',
    iconClass: 'text-blue-400',
    textClass: 'text-blue-700'
  },

  red: {
    contentClass: 'bg-red-100',
    iconClass: 'text-red-400',
    textClass: 'text-red-700'
  },

  amber: {
    contentClass: 'bg-amber-100',
    iconClass: 'text-amber-400',
    textClass: 'text-amber-700'
  },

  violet: {
    contentClass: 'bg-violet-100',
    iconClass: 'text-violet-400',
    textClass: 'text-violet-700'
  },

  emergencyNews: {
    contentClass: 'bg-violet-100',
    iconClass: 'text-violet-400',
    textClass: 'text-violet-700'
  },

  none: {
    contentClass: '',
    iconClass: '',
    textClass: ''
  }
};

<template>
  <SModal
    v-model="shouldUpdate"
    :options="modalOptions"
    @onConfirm="downloadApk"
    @onCancel="shouldUpdate = !shouldUpdate"
  />
</template>

<script lang="ts" setup>
/**
  使用本組件需要讓 android 安裝以下套件：
  - @capacitor-community/file-opener
  - @capacitor/filesystem
  - @capacitor/app
  安裝套件後，執行 npx cap sync 安裝套件至 android
 */

/** 外部方法 */
import { ref, reactive } from 'vue';
import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { FileOpener } from '@capacitor-community/file-opener';
import { Directory, Encoding, Filesystem } from '@capacitor/filesystem';

/** 內部方法 */
import { isProd } from '../../utils/helper';
import useI18n from '../../composables/useI18n';

/* 外部組件 */
import { mdiAlert } from '@mdi/js';

/** 內部組件 */
import SModal from '@sms/components/SModal/SModal.vue';

/** 型別 */
import type { ReadFileOptions } from '@capacitor/filesystem';

/** 系統元件 */
const { t } = useI18n();

interface Props {
  site: string;
}

const props = defineProps<Props>();

const shouldUpdate = ref(false);

const latestApp = reactive({
  version: '',
  sha512: ''
});

const gcpBaseUrl = isProd
  ? `https://storage.googleapis.com/android-${props.site}-prod`
  : `https://storage.googleapis.com/android-${props.site}/${import.meta.env.VITE_BUILDENV}`;

const modalOptions = {
  title: t('Common.LatestVersionAvailable'),
  subtitle: t('Common.AllowUpdate'),
  icon: { path: mdiAlert, class: 'w-12 h-12 text-yellow-500' },
  confirmText: t('Common.Install'),
  cancelText: t('Common.Cancel')
};

const checkVersion = async () => {
  if (!Capacitor.isNativePlatform() || !import.meta.env.VITE_BUILDENV) return;

  const result = await Filesystem.downloadFile({
    method: 'GET',
    url: `${gcpBaseUrl}/latest.json?t=${+new Date()}`,
    path: `latest.json`,
    directory: Directory.Cache
  });

  if (!result.path) return;

  const { data } = await Filesystem.readFile({
    path: `latest.json`,
    directory: Directory.Cache,
    encoding: Encoding.UTF8
  });

  const { version, sha512 } = JSON.parse(data as string) as { version: string; sha512: string };

  const appInfo = await App.getInfo();
  const currentAppVersion = appInfo.version;

  latestApp.sha512 = sha512;
  latestApp.version = version;
  shouldUpdate.value = !!currentAppVersion && currentAppVersion !== latestApp.version;
};

const getSha512 = async (base64: string) => {
  const binaryData = Uint8Array.from(atob(base64), (c) => c.charCodeAt(0));
  const hashArrayBuffer = await crypto.subtle.digest('SHA-512', binaryData);
  const hashArray = Array.from(new Uint8Array(hashArrayBuffer));
  return hashArray.map((byte) => ('00' + byte.toString(16)).slice(-2)).join('');
};

const checkSha512 = async (option: ReadFileOptions) => {
  const { data } = await Filesystem.readFile(option);
  const sha512 = await getSha512(data as string);
  return sha512 === latestApp.sha512;
};

const downloadApk = async () => {
  try {
    if (!Capacitor.isNativePlatform()) return;

    if (!latestApp.version) await checkVersion();

    const apkName = `${props.site}-${import.meta.env.VITE_BUILDENV}-${latestApp.version}.apk`;

    const file = await Filesystem.downloadFile({
      method: 'GET',
      url: `${gcpBaseUrl}/${apkName}`,
      path: apkName,
      directory: Directory.Cache
    });

    if (!file.path) throw Error('Failed to download apk file');

    const isValidSha512 = await checkSha512({
      path: apkName,
      directory: Directory.Cache
    });

    if (!isValidSha512) throw Error('invalid sha512 value');

    await FileOpener.open({
      filePath: file.path,
      openWithDefault: true
    });
  } catch (error) {
    console.error(error);
  } finally {
    shouldUpdate.value = false;
  }
};

checkVersion();
</script>

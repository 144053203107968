/* 外部方法 */
import { createPinia } from 'pinia';

/* 內部方法 */
import useRootStore from '@/store/modules/root';
import useModalStore from '@/store/modules/modal';
import useLocationStore from '@/store/modules/location';
import useMaintenanceStore from '@/store/modules/maintenance';
import useDeskStore from '@/store/modules/desk';
import useShuffleDeskStore from '@/store/modules/shuffleDesk';
import useNewsStore from '@sms/common/store/news';
import useFlagMapStore from '@sms/common/store/flagMap';
import useSocketStore from '@sms/common/store/socket';

/* 型別 */
import type { TabletListenEvents, TabletInvokeEvents } from '@/api/socket';

export const pinia = createPinia();

export default {
  useRootStore,
  useModalStore,
  useLocationStore,
  useMaintenanceStore,
  useNewsStore,
  useDeskStore,
  useShuffleDeskStore,
  useFlagMapStore,
  useSocketStore: useSocketStore<TabletListenEvents, TabletInvokeEvents>()
};

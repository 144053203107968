<template>
  <div :class="rootClass" v-bind="inheritAttrs.attrsWithoutClass">
    <!-- label -->
    <slot name="label">
      <label v-if="label" :for="label" :class="labelClass">
        {{ label }}
      </label>
    </slot>

    <!-- trigger -->
    <Dropdown
      v-model:shown="isSelecterShow"
      auto-size
      auto-hide
      placement="bottom-start"
      :theme="darkMode ? 'sselect-dark' : 'sselect'"
      :distance="5"
      :triggers="['click']"
      :disabled="disabled"
      :popper-class="popperClass"
    >
      <template #default>
        <slot name="activator">
          <div class="flex items-center relative">
            <input readonly :value="valueTextFormat" :class="inputClassMerged" :placeholder="placeholder" />
            <SIcon v-if="icon" :icon="icon" :class="iconClassMerged" />
            <SIcon :icon="mdiMenuDown" :class="menuDownIconClass" />
          </div>
        </slot>
      </template>

      <template #popper>
        <ul>
          <!-- 全選按鈕 -->
          <template v-if="multiple && options.length">
            <li :class="itemSelectAllClass" @click="selectAll">
              <SCheckbox
                class="!absolute !left-2 pointer-events-none"
                :indeterminate="isSomeSelected && !isAllSelected"
                :model-value="isAllSelected"
                :active-class="itemActiveClass"
              />
              <span>{{ multipleText }}</span>
            </li>

            <div class="h-px" :class="darkMode ? 'bg-gray-600' : 'bg-gray-200'" />
          </template>

          <!-- 選項渲染 -->
          <template v-if="options.length">
            <template v-for="(item, index) in options" :key="index">
              <!-- 選項群組表頭 -->
              <li v-if="item.header" class="py-2 px-6 font-medium border-t first:border-t-0">
                {{ item.header }}
              </li>

              <!-- 選項 -->
              <li v-else :class="itemClassMerged(item)" @click="!item.disabled && updateModelValue(item)">
                <!-- 勾選 ICON -->
                <SIcon v-if="isSelected(item) && !multiple" :icon="mdiCheck" class="absolute left-2 w-5 h-5" />

                <!-- 複選框 -->
                <SCheckbox
                  v-if="multiple"
                  class="!absolute !left-2 pointer-events-none"
                  :model-value="isSelected(item)"
                  :active-class="itemActiveClass"
                />

                <div class="line-clamp-1 whitespace-normal break-all">
                  {{ item.text }}
                </div>
              </li>
            </template>
          </template>

          <!-- 無選項時渲染 -->
          <li v-else class="text-center py-1" :class="darkMode ? 'text-gray-400' : 'text-gray-500'">
            {{ props.emptyText }}
          </li>
        </ul>
      </template>
    </Dropdown>

    <!-- Error Messages -->
    <slot name="details" :error-message="errorMessage">
      <div
        v-if="hideDetails === 'auto' ? errorMessage : !hideDetails"
        class="mt-1 text-sm text-red-500 leading-3 inline-flex items-center w-full min-h-[12px]"
      >
        {{ errorMessage }}
      </div>
    </slot>
  </div>
</template>

<script lang="ts">
export default {
  inheritAttrs: false
};
</script>

<script lang="ts" setup>
/* 外部方法 */
import { computed, ref, watch, useAttrs } from 'vue';
import { useField } from 'vee-validate';
import { uniqBy, cloneDeep, isEqual, without, uniq } from 'lodash-es';
import { twMerge } from 'tailwind-merge';

/* 外部組件 */
import { mdiMenuDown, mdiCheck } from '@mdi/js';
import { Dropdown } from 'floating-vue';

/* 內部組件 */
import SCheckbox from '../SCheckbox/SCheckbox.vue';
import SIcon from '../SIcon/SIcon.vue';

/* 型別 */
export interface SelectItem {
  header?: string;
  text?: string;
  value?: string | number | null;
  disabled?: boolean;
}

interface Props {
  // 選擇器相關
  modelValue?: any;
  items?: any[];
  itemText?: string;
  itemValue?: string;
  itemDisabled?: string;
  multiple?: boolean;
  multipleText?: string;

  // 畫面相關
  darkMode?: boolean;
  label?: string;
  disabled?: boolean;
  hideDetails?: boolean | string;
  placeholder?: string;
  emptyText?: string;
  icon?: string;
  dense?: boolean;
  inputClass?: string;
  inputActiveClass?: string;
  itemActiveClass?: string;
  popperClass?: string;

  // 驗證相關
  validateKey?: string;
  validateOnMount?: boolean;
}

interface Emits {
  (e: 'update:model-value', value: any): void;
  (e: 'change', value: any): void;
}

const props = withDefaults(defineProps<Props>(), {
  // 選擇器相關
  items: () => [],
  itemText: 'text',
  itemValue: 'value',
  itemDisabled: 'disabled',

  // 畫面相關
  icon: '',
  multiple: false,
  multipleText: '全選',
  emptyText: '無可用選項',
  disabled: false,
  dense: false,
  inputActiveClass: 'ring-1 ring-indigo-500 border-indigo-500',
  itemActiveClass: 'text-indigo-600',

  // 驗證相關
  validateKey: '',
  validateOnMount: false
});

const emit = defineEmits<Emits>();

/* 選單開關 */
const isSelecterShow = ref(false);

/*
 * 資料邏輯相關
 */

// 將外部傳入的選項過濾為統一格式，並過濾掉相同值的選項
const options = computed(() => {
  const mapToObj = props.items.map((item, index) => {
    if (item && typeof item === 'object') {
      const keys = Object.keys(item);

      return keys.includes('header')
        ? { header: item?.header }
        : { text: item[props.itemText], value: item[props.itemValue], disabled: item[props.itemDisabled] };
    }

    return { text: item, value: item, disabled: false };
  });

  return uniqBy(mapToObj, 'value');
});

// 更新外部值
const updateModelValue = (item: SelectItem) => {
  if (props.multiple) {
    const isArray = Array.isArray(props.modelValue);
    const clone = isArray ? cloneDeep(props.modelValue) : [];

    if (item.value && clone.includes(item.value)) {
      emit('update:model-value', without(clone, item.value));
    } else {
      emit('update:model-value', uniq([...clone, item.value]));
    }

    emit('change', clone);
  } else {
    emit('update:model-value', item.value);
    emit('change', item.value);

    isSelecterShow.value = false;
  }
};

// 複選模式: 是否已經全選
const isAllSelected = computed(() => {
  // 先過濾掉被 disabled 的選項
  const disabledOptionsFiltered = options.value.filter((option) => !option.disabled);

  // 比對是否所有選項都被選中
  return disabledOptionsFiltered.every((option) =>
    Array.isArray(props.modelValue) ? props.modelValue.includes(option.value) : false
  );
});

// 複選模式: 是否部分選中
const isSomeSelected = computed(() => {
  // 先過濾掉被 disabled 的選項
  const disabledOptionsFiltered = options.value.filter((option) => !option.disabled);

  // 比對是否有選項被選中
  return disabledOptionsFiltered.some((option) =>
    Array.isArray(props.modelValue) ? props.modelValue.includes(option.value) : false
  );
});

// 選項是否被選中
const isSelected = (item: SelectItem) =>
  Array.isArray(props.modelValue) ? props.modelValue.includes(item.value) : props.modelValue === item.value;

// 全選功能
const selectAll = () => {
  // 先過濾掉被 disabled 的選項
  const disabledOptionsFiltered = options.value.filter((option) => !option.disabled);

  // map 成只剩下 value 的陣列
  const allValue = disabledOptionsFiltered.map((option) => option.value);

  emit('update:model-value', isAllSelected.value ? [] : allValue);
};

/**
 * 驗證相關
 */
const fieldOptions = {
  initialValue: props.modelValue,
  validateOnMount: props.validateOnMount
};

const { value: validateValue, errorMessage, validate } = useField<any>(props.validateKey, undefined, fieldOptions);

watch(
  () => props.modelValue,
  (val) => {
    if (isEqual(val, validateValue.value)) return;
    validateValue.value = val;
  }
);

watch(validateValue, (val) => {
  if (isEqual(val, props.modelValue)) return;
  emit('update:model-value', val);
});

watch(isSelecterShow, (isShown) => {
  if (!isShown) {
    validate();
  }
});

/* 樣式相關 */

// class 從繼承屬性中排除
const inheritAttrs = computed(() => {
  const { class: attrsClass, ...attrsWithoutClass } = useAttrs();

  return {
    attrsClass,
    attrsWithoutClass
  };
});

// 外層樣式
const rootClass = computed(() => {
  const defaultClass = 'flex flex-col';
  const colorModeClass = props.darkMode ? 'text-gray-400' : 'text-gray-500 ';
  return twMerge(defaultClass, colorModeClass, inheritAttrs.value.attrsClass as string);
});

// 內層樣式
const inputClassMerged = computed(() => {
  const defaultClass = 'relative w-full border rounded text-left outline-none cursor-pointer';
  const paddingClass = props.icon ? 'pl-9 pr-10' : 'pl-4 pr-10';
  const denseClass = props.dense ? 'py-1' : 'py-2';

  const colorModeClass = props.darkMode
    ? 'bg-gray-700 border-gray-700 placeholder:text-gray-400'
    : 'bg-white border-gray-300 placeholder:text-gray-500';

  // 啟用樣式
  const activeClass = isSelecterShow.value && !props.disabled ? props.inputActiveClass : '';

  // 禁用樣式
  const disabledColorModeClass = props.darkMode
    ? 'placeholder:text-gray-500 text-gray-500 cursor-default opacity-60'
    : 'placeholder:text-gray-400 text-gray-400 cursor-default bg-gray-100';

  const disabledClass = props.disabled ? disabledColorModeClass : '';

  // 驗證錯誤樣式
  const errorActiveClass = isSelecterShow.value && errorMessage.value ? 'ring-1 ring-red-500 border-red-500' : '';
  const errorClass = errorMessage.value ? 'border-red-500' : '';

  return twMerge(
    defaultClass,
    paddingClass,
    denseClass,
    colorModeClass,
    props.inputClass,
    activeClass,
    errorClass,
    errorActiveClass,
    disabledClass
  );
});

// LABEL樣式
const labelClass = computed(() => ['block text-sm mb-1', errorMessage.value && 'text-red-500']);

// ICON 樣式
const iconClassMerged = computed(() => {
  const defaultClass = 'absolute left-3 w-5 h-5 cursor-pointer pointer-events-none';

  // 啟用樣式
  const isActivated =
    (Array.isArray(props.modelValue) && props.modelValue.length) ||
    (!Array.isArray(props.modelValue) && props.modelValue);
  const activeClass = isActivated ? props.itemActiveClass : '';

  // 禁用樣式
  const disabledColorModeClass = props.darkMode ? 'text-gray-500' : 'text-gray-400';
  const disabledClass = props.disabled ? disabledColorModeClass : '';

  return twMerge(defaultClass, disabledClass, activeClass);
});

// 下拉 ICON 樣式
const menuDownIconClass = computed(() => {
  const defaultClass = 'absolute right-2 w-5 h-5 pointer-events-none';
  const colorModeClass = props.darkMode ? 'text-gray-300' : '';

  // 禁用樣式
  const disabledColorModeClass = props.darkMode ? 'text-gray-500' : 'text-gray-400';
  const disabledClass = props.disabled ? disabledColorModeClass : '';

  return twMerge(defaultClass, colorModeClass, disabledClass);
});

// 選項啟用樣式
const itemActiveClassMerged = computed(() => {
  const colorModeClass = props.darkMode ? 'bg-gray-900' : '';
  return twMerge(colorModeClass, props.itemActiveClass);
});

// 全選選項樣式
const itemSelectAllClass = computed(() => {
  const defaultClass = 'flex items-center relative select-none pr-4 py-2 pl-10 cursor-pointer';
  const colorModeClass = props.darkMode ? 'text-gray-300 hover:bg-gray-900' : 'text-gray-500 hover:bg-gray-200';
  const activeClass = isAllSelected.value || isSomeSelected.value ? itemActiveClassMerged.value : '';
  return twMerge(defaultClass, colorModeClass, activeClass);
});

// 選項樣式
const itemClassMerged = (item: SelectItem) => {
  const defaultClass = 'flex items-center relative select-none font-medium pr-4 py-2 pl-10';

  const colorModeClass = props.darkMode ? 'text-gray-300 hover:bg-gray-900' : 'text-gray-500';
  const colorModeEnabledClass = props.darkMode
    ? 'hover:bg-gray-900 cursor-pointer'
    : 'hover:bg-gray-200 cursor-pointer';

  const disabledClass = item.disabled ? 'text-gray-400 cursor-not-allowed' : colorModeEnabledClass;
  const activeClass = isSelected(item) ? itemActiveClassMerged.value : '';

  return twMerge(defaultClass, colorModeClass, disabledClass, activeClass);
};

// 格式化選擇中節點的文字
const valueTextFormat = computed(() => {
  if (props.multiple && Array.isArray(props.modelValue)) {
    const str: string[] = [];
    props.modelValue.forEach((x) => {
      options.value.forEach((y) => {
        if (isEqual(x, y.value)) str.push(y.text as string);
      });
    });
    return str;
  }
  const item = options.value.find((x) => isEqual(x.value, props.modelValue));
  return item?.text || '';
});
</script>

<style lang="scss">
.v-popper--theme-sselect,
.v-popper--theme-sselect-dark {
  .v-popper__arrow-container {
    display: none;
  }
}

.v-popper--theme-sselect.v-popper__popper,
.v-popper--theme-sselect-dark.v-popper__popper {
  z-index: 100;
}

.v-popper--theme-sselect,
.v-popper--theme-sselect-dark {
  .v-popper__inner {
    @apply text-sm w-full max-h-[300px] overflow-y-auto py-2;
  }
}

.v-popper--theme-sselect .v-popper__inner {
  @apply bg-white shadow rounded-md border;
}

.v-popper--theme-sselect-dark .v-popper__inner {
  @apply bg-gray-800 shadow-[2px_4px_6px_rgba(0,_0,_0,_0.25)] rounded;
}
</style>

/* API */
import api from '@/api/ajax/settings';

/* 型別 */
import type { AxiosPromise } from 'axios';
import type MaintenanceSet from '@sms/common/models/MaintenanceSet';
import type ResponseBaseModel from '@sms/common/interfaces/ResponseBaseModel';

export default {
  getAllUnfinished(): AxiosPromise<ResponseBaseModel<MaintenanceSet[]>> {
    return api.get('/Maintenance/GetAllUnfinished');
  },

  post(maintenanceSet: MaintenanceSet): AxiosPromise<ResponseBaseModel<MaintenanceSet>> {
    return api.post('/Maintenance', maintenanceSet);
  },

  put(maintenanceSet: MaintenanceSet): AxiosPromise<ResponseBaseModel<MaintenanceSet>> {
    return api.put('/Maintenance', maintenanceSet);
  }
};

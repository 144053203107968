/* eslint-disable no-param-reassign */

/* 外部方法 */
import { LogLevel } from '@microsoft/signalr';

/* API */
import logRecorderService from '../api/logRecorderService';

/* 型別 */
import CustomError from '../models/CustomError';
import type SignalrLogger from '../models/SignalrLogger';
import type { LoggerSite } from '../store/socket';

/**
 * 先用 window.postLog，再考慮原本的 logRecorderService.postLog
 * 基本上只有 dealer 端需要使用
 */

declare global {
  interface Window {
    postLog?: (typeof logRecorderService)['postLog'];
  }
}

const postLog = window.postLog || logRecorderService.postLog;

/** 開始重連 */
export const onreconnectingLogger = (
  site: LoggerSite,
  originalErrorMessage: string | undefined,
  message: string | Record<string, unknown>,
  fromUser: string | undefined,
  machineID: string
) => {
  postLog({
    Msg: [
      {
        ...(typeof message === 'string' ? { Message: message } : message),
        Type: 'SignalR Event',
        Event: 'Reconnecting',
        Site: site,
        ErrorMessage: originalErrorMessage,
        Time: new Date(),
        LogLevel: LogLevel.Error,
        MachineID: machineID
      }
    ],
    LoggerName: site,
    FromUser: fromUser
  });
};

/** 重連成功 */
export const onreconnectedLogger = (
  site: 'admin' | 'tablet' | 'dealer' | 'shuffler',
  message: string | Record<string, unknown>,
  fromUser: string | undefined,
  machineId: string
) => {
  postLog({
    Msg: [
      {
        ...(typeof message === 'string' ? { Message: message } : message),
        Type: 'SignalR Event',
        Event: 'Reconnected',
        Site: site,
        Time: new Date(),
        LogLevel: LogLevel.Error,
        MachineId: machineId
      }
    ],
    LoggerName: site,
    FromUser: fromUser
  });
};

/** 嘗試連線 */
export const onconnectingLogger = (
  site: 'admin' | 'tablet' | 'dealer' | 'shuffler',
  message: string | Record<string, unknown>,
  fromUser: string | undefined,
  machineID: string
) => {
  postLog({
    Msg: [
      {
        ...(typeof message === 'string' ? { Message: message } : message),
        Type: 'SignalR Event',
        Event: 'Connecting',
        Site: site,
        Time: new Date(),
        LogLevel: LogLevel.Error,
        MachineID: machineID
      }
    ],
    LoggerName: site,
    FromUser: fromUser
  });
};

/** 連線成功 */
export const onconnectedLogger = (
  site: 'admin' | 'tablet' | 'dealer' | 'shuffler',
  message: string | Record<string, unknown>,
  fromUser: string | undefined,
  machineID: string
) => {
  postLog({
    Msg: [
      {
        ...(typeof message === 'string' ? { Message: message } : message),
        Type: 'SignalR Event',
        Event: 'Connected',
        Site: site,
        Time: new Date(),
        LogLevel: LogLevel.Error,
        MachineID: machineID
      }
    ],
    LoggerName: site,
    FromUser: fromUser
  });
};

/** 連線關閉 */
export const oncloseLogger = (
  site: 'admin' | 'tablet' | 'dealer' | 'shuffler',
  originalErrorMessage: string | undefined,
  message: string | Record<string, unknown>,
  machineID: string
) => {
  postLog({
    Msg: [
      {
        ...(typeof message === 'string' ? { Message: message } : message),
        Type: 'SignalR Event',
        Event: 'Closed',
        Site: site,
        ErrorMessage: originalErrorMessage,
        Time: new Date(),
        LogLevel: LogLevel.Error,
        MachineID: machineID
      }
    ],
    LoggerName: site
  });
};

/** SignalRLogger 原生 LOG 紀錄 */
export const pushSignalrLogs = async (
  site: 'admin' | 'tablet' | 'dealer' | 'shuffler',
  logger: SignalrLogger,
  fromUser: string | undefined,
  machineID: string
) => {
  if (!logger.logArray.length) return;

  // 複製此時此刻所記錄到的 LOG 並把原始陣列清空
  const logArrayCopy = [...logger.logArray];
  logger.logArray.length = 0;

  try {
    // 推送本次紀錄至 ELK
    const payload = { LoggerName: site, FromUser: fromUser, Msg: logArrayCopy };
    await postLog(payload);
  } catch (error) {
    // 若推送 LOG 發送錯誤則嘗試重新發送一次
    if (error instanceof Error || error instanceof CustomError) {
      const payload = {
        LoggerName: site,
        FromUser: fromUser,
        Msg: [
          {
            Type: 'SignalR Event',
            Event: 'PushLog Error',
            LogLevel: LogLevel.Error,
            Time: new Date(),
            MachineID: machineID,
            Message: `[SignalR Logger 紀錄失敗，已嘗試重新記錄] - 原始錯誤訊息: ${error.message}`
          },
          ...logArrayCopy
        ]
      };

      postLog(payload);
    }
  }
};

export default {
  onreconnectingLogger,
  onreconnectedLogger,
  onconnectingLogger,
  onconnectedLogger,
  oncloseLogger,
  pushSignalrLogs
};

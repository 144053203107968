<template>
  <div class="flex items-center h-full w-full divide-x divide-gray-700 border-t border-gray-700 bg-gray-800">
    <div class="flex-shrink-0 px-2">
      <span class="inline-block rounded-full h-3 w-3 mr-2" :class="mediaServerStatus ? 'bg-green-500' : 'bg-red-500'" />
      <span>{{ t('Common.MediaServer') }}</span>
    </div>
    <div
      ref="statusTextWrapper"
      :title="statusText"
      class="flex-1 px-2 min-w-0 overflow-hidden whitespace-nowrap text-ellipsis"
    >
      <span class="inline-block" :class="{ marqueeEffect: shouldActivateMarqueeEffect }">
        {{ statusText }}
      </span>
    </div>

    <div class="ml-auto flex-shrink-0 px-2 flex items-center gap-x-2">
      <div class="rounded-full w-3 h-3 transition-colors" :class="socketStateClass" />
      {{ socketState ?? 'Unconnected' }}
    </div>
    <div class="flex-shrink-0 px-2">Web: {{ webVersion }}</div>
    <div class="flex-shrink-0 px-2">{{ currentTime }}</div>
    <div class="flex-shrink-0 px-2">{{ environment }}</div>
  </div>
</template>

<script lang="ts" setup>
/* 外部方法 */
import { storeToRefs } from 'pinia';
import { computed, ref, watch, nextTick } from 'vue';
import { useIntervalFn, useTimeoutFn } from '@vueuse/core';

/* 內部方法 */
import store from '@/store';
import { checkServerStatus } from '@sms/common/composables/useSRSRTC';
import useI18n from '@sms/common/composables/useI18n';
import useClock from '@sms/common/composables/useClock';
import useGetBuildInfo from '@sms/common/composables/useGetBuildInfo';

const { t } = useI18n();
const { date, time } = useClock();
const { VITE_BUILD_VERSION } = useGetBuildInfo();
const { socketState, isSocketConnected, isSocketConnecting, isSocketDisconnecting, isSocketReconnecting } = storeToRefs(
  store.useSocketStore()
);

const socketStateClass = computed(() => {
  if (isSocketConnected.value) return 'bg-green-400';
  if (isSocketConnecting.value) return 'bg-green-800';
  if (isSocketReconnecting.value) return 'bg-yellow-400';
  if (isSocketDisconnecting.value) return 'bg-gray-400';
  return 'bg-gray-600';
});

const currentTime = computed(() => `${date.value} ${time.value}`);
const webVersion = VITE_BUILD_VERSION;
const environment = import.meta.env.VITE_BUILDENV;
const statusTextWrapper = ref<HTMLElement>();
const shouldActivateMarqueeEffect = ref(false);

// 取得 Media Server 狀態
const mediaServerStatus = ref(true);
const mediaServerChecker = useTimeoutFn(
  async () => {
    mediaServerStatus.value = await checkServerStatus(window.location.href, import.meta.env.VITE_SRS_HOST || '');
    mediaServerChecker.start();
  },
  5000,
  { immediate: true }
);

/* 文字狀態 */
const statusTexts = computed(() => {
  const msgs: string[] = [];

  if (!mediaServerStatus.value) {
    msgs.push(t('Error.MediaServer_Down'));
  }

  if (msgs.length === 0) {
    msgs.push(t('Error.StatusOK'));
  }

  return msgs;
});

const statusIndex = ref(0);
useIntervalFn(
  () => {
    statusIndex.value = (statusIndex.value + 1) % statusTexts.value.length;
  },
  5000,
  { immediate: true, immediateCallback: true }
);

const statusText = computed(() => statusTexts.value[statusIndex.value]);

watch(
  statusText,
  () => {
    nextTick(() => {
      if (!statusTextWrapper.value) return;

      const isTextOverflowed = statusTextWrapper.value?.scrollWidth > statusTextWrapper.value?.offsetWidth;
      shouldActivateMarqueeEffect.value = isTextOverflowed;
    });
  },
  {
    immediate: true
  }
);
</script>

<style lang="scss" scope>
.marqueeEffect {
  animation: marquee 10s linear infinite;
}
@keyframes marquee {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-100%);
  }
}
</style>

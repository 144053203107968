/* 外部方法 */
import { defineStore, storeToRefs } from 'pinia';
import { ref, computed } from 'vue';
import { sortBy, uniq } from 'lodash-es';

/* 內部方法 */
import useRootStore from './root';
import useLocationStore from './location';
import useFlagmapStore from '@sms/common/store/flagMap';
import useI18n from '@sms/common/composables/useI18n';
import { alertTimeFormat } from '@/components/Alerts/helper';
import { isDevelopment, detailLangTypeInit, findParentByLocationId } from '@sms/common/utils/helper';

/* API */
import maintenanceService from '@/api/ajax/maintenanceService';

/* 型別 */
import type MaintenanceSet from '@sms/common/models/MaintenanceSet';
import type MaintenanceSetDetail from '@sms/common/models/MaintenanceSetDetail';
import type MaintenanceSetLocationSet from '@sms/common/models/MaintenanceSetLocationSet';
import type { AlertBaseModel } from '@/components/Alerts/types';

export default defineStore('maintenance', () => {
  /* 系統元件 */
  const { t } = useI18n();

  /* Stores */
  const rootStore = useRootStore();
  const locationStore = useLocationStore();
  const { flagMapGroupList } = useFlagmapStore();

  const { currentStudioId, userLocale } = storeToRefs(rootStore);

  /* Flagmap 相關 */
  const { LangType, MaintenanceStatus, LocationType, MaintenanceType } = flagMapGroupList;
  const { STUDIO, EXCLUSIVE_STUDIO, SHUFFLEROOM } = LocationType;
  const { Notify, Running } = MaintenanceStatus;

  /* 地區相關 */
  const { locationFlat } = storeToRefs(locationStore);

  /* 後端回傳的原始維護列表 */
  const allMaintenanceOriginal = ref<MaintenanceSet[]>([]);

  /* 將維護列表內的維護文字內容對齊語系資料並依照使用者語系排序 */
  const allMaintenanceWithSortedDetail = computed(() =>
    allMaintenanceOriginal.value.map((maintenance) => ({
      ...maintenance,
      MaintenanceSetDetails: detailLangTypeInit<MaintenanceSetDetail>(
        LangType,
        maintenance.MaintenanceSetDetails,
        rootStore.user.LangTypeCode,
        true
      )
    }))
  );

  /* 將維護列表轉換為前端通知組件格式 */
  const allMaintenanceAlertFormat = computed(() => {
    // 過濾出狀態為 Notify 及 Running 的維護
    const maintNotifyAndRunningFilter = allMaintenanceOriginal.value.filter(
      (maint) => maint.MaintenanceStatusCode === Notify || maint.MaintenanceStatusCode === Running
    );

    const result: AlertBaseModel<MaintenanceSet>[] = [];

    // 將維護的地區廳別轉成名稱陣列
    const studiosFormat = (locationSets: MaintenanceSetLocationSet[]) =>
      uniq(
        locationSets
          .map(
            (locationSet) =>
              findParentByLocationId(locationSet.LocationSetId, locationFlat.value, [STUDIO, EXCLUSIVE_STUDIO])?.Name
          )
          .filter((name): name is string => !!name)
      ).join(', ');

    // 將維護的地區轉為名稱陣列
    const tablesFormat = (locationSets: MaintenanceSetLocationSet[]) =>
      locationSets
        .map((locationSet) => locationFlat.value.find((l) => l.Id === locationSet.LocationSetId)?.Name)
        .filter((name): name is string => !!name)
        .join(', ');

    maintNotifyAndRunningFilter.forEach((maint) => {
      const isUrgent = maint.MaintenanceTypeCode === MaintenanceType.Urgent;

      const title = isUrgent ? t('Tablet.UrgentMaintenance') : t('Tablet.NormalMaintenance');
      const subTitle = maint.MaintenanceSetDetails.find((detail) => detail.LangType === userLocale.value)?.Title;

      const studioName = studiosFormat(maint.MaintenanceSetLocationSets);
      const tableName = tablesFormat(maint.MaintenanceSetLocationSets);

      const content = `
        <p>
          <span class="font-bold">${t('Tablet.Notification_StudioName')}:</span>
          <span>${studioName}</span>
        </p>
        <p class="text-center">
          <span class="font-bold">${t('Tablet.Notification_TableName')}:</span>
          <span>${tableName}</span>
        </p>
      `;

      const timeFormat = isUrgent
        ? alertTimeFormat(new Date(maint.StartTime))
        : `${alertTimeFormat(new Date(maint.StartTime))} ~\n${alertTimeFormat(new Date(maint.EndTime))}`;

      result.push({
        id: maint.Id as string,
        title,
        subTitle: subTitle ? `${subTitle}\n${timeFormat}` : timeFormat,
        type: 'blue',
        closable: false,
        serverData: maint,
        modal: {
          date: timeFormat,
          title,
          content,
          type: 'blue'
        }
      });
    });

    return result;
  });

  /* 對應當前廳別的維護列表 (不包含緊急維護) */
  const normalMaintenanceWithCurrentStudio = computed(() => {
    const normalMaints = allMaintenanceWithSortedDetail.value.filter(
      (maint) => maint.MaintenanceTypeCode === MaintenanceType.Normal
    );

    // 判斷廳別維護 (同一筆維護可能跨廳別)
    const studioFilter = normalMaints.filter((maint) => {
      const studios: string[] = [];

      maint.MaintenanceSetLocationSets.forEach((location) => {
        const studio = findParentByLocationId(location.LocationSetId, locationFlat.value, [
          STUDIO,
          EXCLUSIVE_STUDIO,
          SHUFFLEROOM
        ]);
        if (studio?.Id) studios.push(studio.Id);
      });

      return studios.includes(currentStudioId.value);
    });

    return studioFilter;
  });

  const fetchMaintenaceList = async () => {
    try {
      const resp = await maintenanceService.getAllUnfinished();

      const maintDatetimeFormat = resp.data.Data.map((maint) => ({
        ...maint,
        StartTime: new Date(maint.StartTime),
        EndTime: new Date(maint.EndTime)
      }));

      const maintSortedByDate = sortBy(maintDatetimeFormat, (maint) => +maint.StartTime);

      allMaintenanceOriginal.value = maintSortedByDate;
    } catch (error) {
      if (isDevelopment) console.error(error);
    }
  };

  const getStudioLocationTypeCodeById = (id?: string) =>
    findParentByLocationId(id, locationFlat.value, [STUDIO, EXCLUSIVE_STUDIO, SHUFFLEROOM])?.LocationTypeCode;

  return {
    allMaintenanceOriginal,
    allMaintenanceWithSortedDetail,
    allMaintenanceAlertFormat,
    normalMaintenanceWithCurrentStudio,
    fetchMaintenaceList,
    getStudioLocationTypeCodeById
  };
});

/* 外部方法 */
import { defineStore } from 'pinia';

/* 型別 */
import type { ModalOption } from '@sms/components/SModal/types';

export interface ModalInstance extends ModalOption {
  onCancel?: () => void;
  onConfirm?: () => void;
}

export default defineStore('modal', {
  state: () => ({
    modals: [] as ModalInstance[]
  }),

  actions: {
    add(payload: ModalInstance) {
      this.modals.push(payload);
    },

    remove(payload: ModalInstance) {
      const index = this.modals.indexOf(payload);
      if (index > -1) this.modals.splice(index, 1);
    },

    clear() {
      this.modals.length = 0;
    },

    confirm(payload: ModalInstance) {
      return new Promise((resolve) => {
        const instance: ModalInstance = {
          ...payload,
          onCancel: () => {
            if (payload.onCancel) payload.onCancel();
            resolve(false);
            this.remove(instance);
          },
          onConfirm: () => {
            if (payload.onConfirm) payload.onConfirm();
            resolve(true);
            this.remove(instance);
          }
        };

        this.add(instance);
      });
    }
  }
});

/* 外部方法 */
import { ref, watch, onBeforeUnmount } from 'vue';
import { useIntervalFn, useDocumentVisibility } from '@vueuse/core';
import QrScanner from 'qr-scanner';

/* 內部方法 */
import useScannerParser from './useScannerParser';
import { isDevelopment } from '../utils/helper';

/* 型別 */
import type { ScanResult } from './useScannerParser';

export default function useQRScanner(captureImageCallback: (scanData: ScanResult) => void) {
  const scanner = useScannerParser();

  const videoElement = ref<HTMLVideoElement>();
  const stream = ref<MediaStream | null>(null);
  const permissionNotAllowed = ref(false);
  const docVisibility = useDocumentVisibility();

  /** 捕捉影像 */
  const captureImage = async () => {
    try {
      if (!(videoElement.value?.srcObject instanceof MediaStream)) {
        throw new Error('malformed video source detected.');
      }

      if (videoElement.value.readyState !== HTMLVideoElement.prototype.HAVE_ENOUGH_DATA) return; // 視訊還沒準備好

      const { data } = await QrScanner.scanImage(videoElement.value, { returnDetailedScanResult: true });

      if (data) captureImageCallback(scanner.parse(data));
    } catch (error) {
      if (isDevelopment) console.error(error);
    }
  };

  /** 連續捕捉影像開關 */
  const captureImageInterval = useIntervalFn(() => requestAnimationFrame(captureImage), 100, {
    immediate: false,
    immediateCallback: true
  });

  /** 注入影像 */
  const initVideo = async () => {
    try {
      const mediaStream = await navigator.mediaDevices.getUserMedia({
        video: { facingMode: { ideal: 'environment' } }
      });

      if (docVisibility.value === 'hidden') {
        mediaStream.getVideoTracks().forEach((track) => track.stop());
        return;
      }

      stream.value = mediaStream;

      if (videoElement.value && stream.value) {
        videoElement.value.srcObject = stream.value;
      }

      permissionNotAllowed.value = false;
      captureImageInterval.resume();
    } catch (error) {
      if (error instanceof DOMException) {
        permissionNotAllowed.value = true;
      }
    }
  };

  /** 影像停止 */
  const stopVideo = () => {
    captureImageInterval.pause();

    if (videoElement.value?.srcObject instanceof MediaStream) {
      videoElement.value.srcObject.getVideoTracks().forEach((track) => track.stop());
    }
  };

  /** 偵測 HTML 自動綁定/停止 stream */
  watch(
    videoElement,
    (val) => {
      if (val) {
        initVideo();
      } else {
        stopVideo();
      }
    },
    { immediate: true }
  );

  /** 偵測該分頁活動狀態，自動關閉攝相機 */
  watch(docVisibility, (visibilityState) => {
    if (visibilityState === 'visible' && videoElement.value) {
      initVideo();
    } else {
      stopVideo();
    }
  });

  onBeforeUnmount(stopVideo);

  return {
    permissionNotAllowed,
    videoElement,
    stream,
    captureImageInterval,
    initVideo,
    stopVideo
  };
}

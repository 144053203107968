/* 內部方法 */
import store from '@/store';
import useI18n from '@sms/common/composables/useI18n';

/* 外部方法 */
import { mdiAlertOutline } from '@mdi/js';

/* 型別 */
import type Pinia from 'pinia';
import type { ModalOption } from '@sms/components/SModal/types';
import type { ModalInstance } from '@/store/modules/modal';

export default function useModal(pinia: Pinia.Pinia | null = null) {
  const modalStore = store.useModalStore(pinia);
  const { t } = useI18n();

  /**
   * 純確認視窗，無取消按鈕
   */
  const modalOk = (options: ModalInstance = {}) => {
    const defaultOptions: ModalOption = {
      contentClass: 'rounded-2xl',
      titleClass: 'text-black font-bold text-xl mt-8',
      subtitleClass: 'text-gray-500 mb-3 mt-0',
      cancelClass: 'mb-7 !rounded-lg !font-bold !text-gray-400',
      confirmClass: 'mb-7 !rounded-lg !font-bold !text-white bg-emerald-500',
      title: '',
      subtitle: '',
      confirmText: 'OK'
    };

    return modalStore.confirm({ ...defaultOptions, ...options });
  };

  /**
   * 確認視窗，帶取消/確認按鈕
   */
  const modalConfirm = (options: ModalInstance = {}) => {
    const defaultOptions: ModalOption = {
      contentClass: 'rounded-2xl',
      titleClass: 'text-black font-bold text-xl mt-8',
      subtitleClass: 'text-gray-500 mb-3 mt-0',
      cancelClass: 'mb-7 !rounded-lg !font-bold !text-gray-400',
      confirmClass: 'mb-7 !rounded-lg !font-bold !text-white bg-emerald-500',
      title: t('Common.Modal_Confirm_Title'),
      subtitle: '',
      cancelText: t('Common.Cancel'),
      confirmText: t('Common.Confirm')
    };

    return modalStore.confirm({ ...defaultOptions, ...options });
  };

  /**
   * 警告確認視窗，帶取消/確認按鈕 (預設文字 for 刪除)
   */
  const modalDelete = (options: ModalInstance = {}) => {
    const defaultOptions: ModalOption = {
      icon: { path: mdiAlertOutline, class: 'w-12 h-12 text-red-500' },
      title: t('Common.Modal_Delete_Title'),
      subtitle: t('Common.Modal_Delete_Content'),
      cancelText: t('Common.Cancel'),
      confirmText: t('Common.Confirm')
    };

    return modalStore.confirm({ ...defaultOptions, ...options });
  };

  return {
    modalOk,
    modalConfirm,
    modalDelete
  };
}

/* 外部方法 */
import { ref, computed, readonly } from 'vue';
import { defineStore } from 'pinia';
import { v4 } from 'uuid';
import { useStorage } from '@vueuse/core';

/* 內部方法 */
import { checkRolePermission } from '@sms/common/utils/helper';

/* 型別 */
import MemberSet from '@sms/common/models/MemberSet';
import type SiderbarItem from '@sms/common/interfaces/SiderbarItem';

export default defineStore('root', () => {
  /* 登入相關參數 */
  const token = ref('');
  const isLoggedIn = ref(false);

  /* 使用者相關參數 */
  const user = ref<MemberSet>(new MemberSet());
  const userId = ref('');
  const userRoleType = ref('');
  const userLocale = useStorage('userLocale', 'en');
  const siderbar = ref<SiderbarItem[]>([]);

  /* 彈窗 */
  const isDiceModalShow = ref(false);

  // 目前使用者選單
  const currentSiderbar = computed(() =>
    siderbar.value.filter((feature) => checkRolePermission(feature.RoleTypeCode, [userRoleType.value]))
  );

  /* 系統操作相關參數 */
  const currentStudioId = ref('');

  /* LOG 相關 */
  const machineId = useStorage('machineId', '');

  const tokenInit = (payload: string) => {
    token.value = payload;
  };

  const machineIdInit = () => {
    if (machineId.value) return;
    machineId.value = v4();
  };

  const resetStore = () => {
    user.value = new MemberSet();
    userId.value = '';
    userRoleType.value = '';
    tokenInit('');
  };

  /* android 鏡頭是否開啟 */
  const isCameraActive = ref(false);

  return {
    token: readonly(token),
    isLoggedIn,
    siderbar,
    user,
    userId,
    userRoleType,
    userLocale,
    currentStudioId,
    machineId: readonly(machineId),
    currentSiderbar,
    isDiceModalShow,
    isCameraActive,
    machineIdInit,
    tokenInit,
    resetStore
  };
});

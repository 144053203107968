/* 型別 */
import type DeskSet from './DeskSet';
import type ShuffleBoxSet from './ShuffleBoxSet';
import type ShuffleTimeSet from './ShuffleTimeSet';
export default class ShuffleDeskSet {
  Id? = '';

  Name = '';

  Code = '';

  MemberId = '';

  LocationId? = '';

  Seq = 0;

  CreateDatetime = new Date();

  UpdateDatetime = new Date();

  Status = true;

  MemberNickname = '';

  WarningStatusCode = '0';

  IsLogin = true;

  IsDelete = false;

  IsReverse = false;

  ErrorExclusionId? = '';

  ShuffleBoxId? = '';

  StartTime = new Date();

  EndTime = new Date();

  ShuffleStatusCode = '0';

  ShuffleTypeCode = '0';

  ShuffleRoomLocationId = '';

  ShuffleTimeId = '';

  IsMaintenance = false;

  ShuffleBox?: ShuffleBoxSet;

  ShuffleTimeSet?: ShuffleTimeSet;

  ShuffleTimeSets?: ShuffleTimeSet[];

  DeskSets?: DeskSet[];
}

/* API */
import api from '@/api/ajax/settings';

/* 型別 */
import type { AxiosPromise } from 'axios';
import type ResponseBaseModel from '@sms/common/interfaces/ResponseBaseModel';
import type MemberSet from '@sms/common/models/MemberSet';

export default {
  getDealer(id: string): AxiosPromise<ResponseBaseModel<MemberSet>> {
    return api.get(`/Member/GetDealer/${id}`);
  },

  getShuffler(shuffleDeskId: string): AxiosPromise<ResponseBaseModel<MemberSet>> {
    return api.get(`/Member/GetShuffler/${shuffleDeskId}`);
  }
};

<template>
  <main class="flex w-full h-full justify-center items-center">
    <p class="text-4xl font-extrabold text-indigo-600 sm:text-5xl">404</p>
    <div class="ml-6">
      <div class="sm:border-l sm:border-gray-200 sm:pl-6">
        <h1 class="text-4xl font-extrabold text-white tracking-tight sm:text-5xl">Page not found</h1>
      </div>
    </div>
  </main>
</template>
